import { Catalog } from "react-planner";

let catalog = new Catalog();

import * as Areas from "./areas/**/planner-element.jsx";
import * as Lines from "./lines/**/planner-element.jsx";
import * as Holes from "./holes/**/planner-element.jsx";
import * as Items from "./items/**/planner-element.jsx";

for (let x in Areas) catalog.registerElement(Areas[x]);
for (let x in Lines) catalog.registerElement(Lines[x]);
for (let x in Holes) catalog.registerElement(Holes[x]);
for (let x in Items) catalog.registerElement(Items[x]);

catalog.registerCategory("doors", "Doors", [
  Holes.door,
  Holes.doorDouble,
  Holes.panicDoor,
  Holes.panicDoorDouble,
  Holes.slidingDoor
]);
catalog.registerCategory("windows", "Windows", [
  Holes.window,
  Holes.sashWindow,
  Holes.venetianBlindWindow,
  Holes.windowCurtain
]);
catalog.registerCategory("Small-Startup", "Small-Startup", [
  Items.smallpack,
  Items.smallbackpack,
  Items.smallleftpack,
  Items.smallrightpack,
  Items.smallbothlaterals,
  Items.smallleftwithback,
  Items.smallrightwithback,
  Items.smallbothsideswithback,
  Items.smallcupboardround,
  Items.smallcupboardlong
]);

catalog.registerCategory("Small-Right-Base", "Small-Right-Base", [
  Items.bookcase,
  Items.wardrobe
]);

catalog.registerCategory("Small-Top-Leftmost", "Small-Top-Leftmost", [
  Items.bookcase,
  Items.wardrobe
]);

catalog.registerCategory("Small-Top-Right", "Small-Top-Right", [
  Items.bookcase,
  Items.wardrobe
]);


export default catalog;
